'use client';

// App components
import Header from '../component/AppHeader/Header';
import Footer from '../component/AppFooter';

// Mui components
import {Box, useMediaQuery} from '@mui/material';

//  Component
const ContentPublicLayout = ({children}: {children: React.ReactNode}) => {
  // * Responsive
  const isMobile = useMediaQuery('(max-width:480px)');

  // * Template
  return (
    <>
      <Header />

      <Box
        sx={{
          backgroundColor: 'additionalBackground',
          pb: isMobile ? '40px' : '130px',
        }}
      >
        <main>{children}</main>
      </Box>
      <Footer />
    </>
  );
};

export default ContentPublicLayout;
