'use client';

import React from 'react';
import Image from 'next/image';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Container,
  IconButton,
} from '@mui/material';

import Logo from '@/sharedComponents/Logo';
import { useTranslations } from 'next-intl';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { Link } from '@/utils/navigation';

interface ContactListItemProps {
  icon: React.ElementType;
  children: React.ReactNode;
}

interface SimpleListItemProps {
  href: string;
  children: React.ReactNode;
}

const CONTACT_PHONE = '+38761240230';
const CONTACT_EMAIL = 'info@balkantransfer.com';

const ContactListItem = ({ icon: Icon, children }: ContactListItemProps) => (
  <ListItem
    sx={{
      padding: 0,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '16px',
    }}
  >
    <Icon sx={{ fontSize: 20 }} />
    <ListItemText primary={<Typography variant="bodySmallRegular">{children}</Typography>} />
  </ListItem>
);

const SimpleListItem = ({ href, children }: SimpleListItemProps) => (
  <ListItem sx={{ padding: 0 }}>
    <Link href={href}>
      <ListItemText
        primary={
          <Typography variant="bodySmallRegular" sx={{ mb: 2 }}>
            {children}
          </Typography>
        }
      />
    </Link>
  </ListItem>
);

const Footer = () => {
  const t = useTranslations('FooterLinks');

  // * Methods
  const navToInstagram = () => {
    window.open('https://www.instagram.com/balkan_transfer/', '_blank');
  };

  const navToFacebook = () => {
    window.open('https://www.facebook.com/balkantransfer', '_blank');
  };

  return (
    <Box bgcolor="white" color="onSurface" py={4}>
      <Container maxWidth="xl">
        <Grid container spacing={6}>
          <Grid item xs={12} md={4} lg={3}>
            <Box display="flex" flexDirection="column" gap="40px">
              <Image src="/BT_badge.svg" alt="balkan transfer" width={52} height={68} />
              <Logo color="colour" width={136} height={48} />
            </Box>
          </Grid>

          <Grid item xs={12} md={8} lg={9} container spacing={6}>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="bodyMedium" color="primary">
                {t('ContactUs')}
              </Typography>
              <List sx={{ padding: 0, mt: 3 }}>
                <ContactListItem icon={LocalPhoneOutlinedIcon}>
                  <a href={`tel:${CONTACT_PHONE}`}>{CONTACT_PHONE}</a>
                </ContactListItem>
                <ContactListItem icon={EmailOutlinedIcon}>
                  <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
                </ContactListItem>
              </List>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="bodyMedium" color="primary">
                {t('BalkanTransfer')}
              </Typography>
              <List sx={{ padding: 0, mt: 3 }}>
                <SimpleListItem href="/about-us">{t('AboutUs')}</SimpleListItem>
                <SimpleListItem href="/safety-and-quality">
                  {t('QualityAndSecurity')}
                </SimpleListItem>
              </List>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="bodyMedium" color="primary">
                {t('TermsAndConditions')}
              </Typography>
              <List sx={{ padding: 0, mt: 3 }}>
                <SimpleListItem href="/terms-and-service">{t('TermsAndService')}</SimpleListItem>
                <SimpleListItem href="/privacy-policy">{t('PrivacyPolicy')}</SimpleListItem>
              </List>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <List sx={{ display: 'flex', gap: { xs: 3, md: 0, lg: 3 } }}>
                <Image
                  src="/assets/images/mastercard.png"
                  alt="mastercard"
                  width={48}
                  height={30}
                />

                <Image src="/assets/images/visa.png" alt="visa" width={48} height={30} />

                <Image src="/assets/images/amex.png" alt="amex" width={48} height={30} />
              </List>
              <List sx={{ display: 'flex', gap: 3 }}>
                <IconButton aria-label="instagram" onClick={navToInstagram}>
                  <Image
                    src="/assets/images/instagram.png"
                    alt="instagram"
                    width={30}
                    height={30}
                  />
                </IconButton>
                <IconButton aria-label="facebook" onClick={navToFacebook}>
                  <Image src="/assets/images/facebook.png" alt="facebook" width={30} height={30} />
                </IconButton>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: { xs: 2, xl: '64px' } }} />
        <Box
          textAlign="center"
          pt={6}
          pb={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: 16,
            flexDirection: { xs: 'column', lg: 'row' },
          }}
          color="primary"
        >
          <Typography variant="bodySmallRegular">
            {t('Copyrigth')} {new Date().getFullYear()}, <strong>{t('SafeRide')}</strong>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
